::-webkit-file-upload-button {
    display: none;
}

#file-upload {
    /* display: block; */
    position: relative;
    left: 40%;
}

.fileTypeError{
    font-weight: bold;
    position: absolute;
    padding-left: 20px;
    right: 11%;
    top: 35%;
    background-color: rgb(236, 142, 142);
    color: white;
    width: 500px;
    height: 180px;
    font-size: 20px;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadedAlert{
    font-weight: bold;
    position: absolute;
    right: 11%;
    top: 32%;
    background-color: rgb(140, 211, 140);
    color: white;
    width: 500px;
    height: 210px;
    font-size: 20px;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}