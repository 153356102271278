body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.responsiveTable thead tr {
  border-bottom: 2px solid #333;
}
.Toastify__toast-body{
  font-family:  "Montserrat";
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: " Poppins";
  font-style: "normal"; */
  /* z-index: 99; */
}

.main-container {
  display: flex;
}

main {
  padding: 10px;
}
.makeStyles-modalStyle-149::-webkit-scrollbar {
  width: 0 !important;
}

.makeStyles-table-133 {
  width: 100% !important;
}
.makeStyles-modalStyle-279::-webkit-scrollbar {
  width: 0 !important;
}
.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user-icons {
  font-size: 20px;
  cursor: pointer;
  color: #787885;
  /* text-align: 'center' */
}

.select {
  font-size: 19px;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: 22.5px;
  color: #8392ab;
  border: 0;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiTableCell-root {
  padding: 16px 3px !important;
}

.MuiStepper-root {
  margin-left: 0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  color: #adadad;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

/* .ant-picker:hover, .ant-picker-focused {
  border-color: yellow !important;
  border-right-width: 1px;
}

.anticon svg {
  display: none !important;
} */

::-webkit-input-placeholder {
  font-family: "Montserrat";
}
/* .ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px !important;
  margin-right: 10px !important;
  order: -1 !important;
} */

/* .input[type="text"],  input[type="number"], textarea  {
  -webkit-appearance: none;
  color: red;
} */

/* .MuiOutlinedInput-inputAdornedEnd {
  padding-right: 0 !important;
  color: #444445 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;

} */
/* .datepicker__title {
  position: absolute;
  top: 11px;
  margin-left: 15px;
  font-weight: 400;
  z-index: 1000;
  color: #8392AB;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
}

.datepicker__to_title {
  position: absolute;
  top: 11px;
  margin-left: 190px;
  font-weight: 400;
  z-index: 1000;
  color: #8392AB;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
} */

/* .ant-picker-input > input {
  border-color: #40a9ff;
  border-right-width: 1px;
  width: 80px;
  margin-left: 50px;
} */

/* .ant-picker-input > input {
  margin-left: 0 !important;
} */

.sidebar {
  width: "175vh";
}

.sidebarContent {
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.07em;
  font-weight: 400;
  font-size: 16px;
  height: 60px;
}

.pro-sidebar {
  width: 20vw;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
}

.scrollbarStyle {
  overflow-y: scroll;
  height: 100vh;
  /* webkit scrollbar */
}

/* .scrollbarStyle::-webkit-scrollbar {
} */

.scrollbarStyle::-webkit-scrollbar {
  width: 12px;
}

.scrollbarStyle::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 100px;
}

.scrollbarStyle::-webkit-scrollbar-thumb {
  background-color: #b4b2b0;
  border-radius: 100px;
  margin-inline: 5px;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: 100vh;
}

.pro-sidebar .pro-menu .pro-menu-item {
  color: #444445;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  .pro-inner-item
  .pro-icon-wrapper
  .pro-icon
  .active {
  color: #e94141;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #444445;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
}

.pro-sidebar .pro-menu a:hover,
.pro-sidebar .pro-menu .pro-menu-item .pro-inner-item:hover {
  color: #444445 !important;
  font-weight: 600 !important;
  font-size: "16px" !important;
  line-height: "19.5px" !important;
}

.pro-sidebar .pro-menu {
  color: red;
}

.pro-sidebar .pro-menu ul .pro-sub-menu .pro-inner-list-item {
  position: relative;
  /* background-color: #2b2b2b; */
}

.sidebar-header {
  height: 15vh;
}

.icon {
  flex-direction: "row";
  align-items: "flex-end";
  margin-left: 250px !important;
  color: red;
}

.subname {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.1em;
  font-style: normal;
  line-height: 50px;
  margin-top: 30px;
  margin-left: -11.5rem;
}

@-webkit-keyframes animate {
  0%,
  18%,
  20%,
  50.1%,
  60%,
  65.1%,
  80.1%,
  90%,
  92% {
    color: black;
    text-shadow: none;
  }

  0%,
  18.1%,
  20%,
  50%,
  60.1%,
  65%,
  80.1%,
  90.1%,
  92%,
  100% {
    color: #0008;
    box-shadow: 0 0 10px #000000 0 0 20px #000000 0 0 40px #000000 0 0 80px
      #000000 0 0 160px #000000;
  }
}

@keyframes animate {
  0%,
  18%,
  20%,
  50.1%,
  60%,
  65.1%,
  80.1%,
  90%,
  92% {
    color: black;
    text-shadow: none;
  }

  0%,
  18.1%,
  20%,
  50%,
  60.1%,
  65%,
  80.1%,
  90.1%,
  92%,
  100% {
    color: #0008;
    box-shadow: 0 0 10px #000000 0 0 20px #000000 0 0 40px #000000 0 0 80px
      #000000 0 0 160px #000000;
  }
}

.postname {
  color: #ffffff;
  font-weight: 900;
  font-size: 40px;
  letter-spacing: 0.15em;
  line-height: 50px;
  margin-bottom: 10px;
  cursor: pointer;
}

.name {
  display: flex;
  position: absolute;
  left: 1.9rem;
  top: 0.5rem;
  padding-left: 5px;
}

.smallname {
  display: flex;
  position: absolute;
  left: 1rem;
  top: 0.5rem;
}

.sidebar-footer {
  position: absolute;
  bottom: 0px;
}

.active {
  color: #444445 !important;
  font-weight: 600 !important;
  font-size: "16px" !important;
  line-height: "19.5px" !important;
}

.active .active-icon {
  color: yellow !important;
}

.link {
  color: rgba(68, 68, 69, 0.7);
}

.react-slidedown,
.pro-inner-list-item {
  background-color: #ffffff !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  flex-grow: 1;
  flex-shrink: 1;
  color: #444445;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  border-color: #292d32;
}
@media (max-width: 600px) {
  .pro-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 0;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
    margin-top: 50px;
  }
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  background-color: #fff !important;
  z-index: 999999 !important;
}

.pro-sidebar.collapsed {
  z-index: 9999999;
}

.subinmenu .pro-inner-list-item{
  padding-left: 8px !important;
}
.subinmenu .pro-inner-item{
  padding-left: 0px !important;
  margin-left: 20px;
}

.dealerFilter .ant-picker-input>input {
    margin-left: 0 !important;
}


::-webkit-file-upload-button {
    display: none;
}

#file-upload {
    /* display: block; */
    position: relative;
    left: 40%;
}

.fileTypeError{
    font-weight: bold;
    position: absolute;
    padding-left: 20px;
    right: 11%;
    top: 35%;
    background-color: rgb(236, 142, 142);
    color: white;
    width: 500px;
    height: 180px;
    font-size: 20px;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadedAlert{
    font-weight: bold;
    position: absolute;
    right: 11%;
    top: 32%;
    background-color: rgb(140, 211, 140);
    color: white;
    width: 500px;
    height: 210px;
    font-size: 20px;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ok{
   
    width: 100%;
    font-size: 16px;
    background-color: #FEDD17;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    outline: none;
    border: none;
 
   
}

/* .Toastify__toast-container--top-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
} */

.toastify-toaster {
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  width: 80% !important;
}

.responsiveTable thead tr {
    border-bottom: 2px solid #333;
  }
.date-picker {
  position: absolute;
  top: 11px;
  z-index: 10 !important;
  font-family: "Montserrat";
  left: 143px;
}

.ant-picker-input > input {
  border-color: #40a9ff;
  border-right-width: 1px;
  width: 80px;
  margin-left: 50px;
  font-family: Montserrat !important;
  font-size: 12px !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
  font-family: "Montserrat";
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
  font-family: "Montserrat";
}

.ant-picker-panels > *:last-child {
  display: none !important;
  font-family: "Montserrat";
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
  font-family: "Montserrat";
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
  font-family: "Montserrat";
}

.DayPicker-wrapper {
  outline: none;
  font-family: "Montserrat";
}

.datepicker__title {
  position: absolute;
  top: 11px;
  margin-left: 15px;
  font-weight: 400;
  z-index: 500;
  color: #8392ab;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
}

.datepicker__to_title {
  position: absolute;
  top: 11px;
  left: 210px;
  font-weight: 400;
  z-index: 500;
  color: #8392ab;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
}

.ant-picker:hover {
  border-color: #fedd17 !important;
  border-width: 1px;
  font-family: "Montserrat";
}

.ant-picker.ant-picker-focused {
  border-color: #fedd17 !important;
  border-width: 2px;
  font-family: "Montserrat";
}

.ant-picker {
  box-shadow: none !important;
  font-family: "Montserrat";
}

.ant-picker-input > input:placeholder {
  color: red !important;
  font-family: "Montserrat";
}

.anticon svg {
  display: none !important;
  font-family: "Montserrat";
}
.pagination {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 85px;
}
.paginationStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paginationPage {
  margin-left: 7px;
  font-family: "Montserrat";
}

@media (max-width: 428px) {
  .paginationStyle {
    margin-bottom: 10px;
  }
  .datepicker__title {
    margin-left: 14px;
    font-size: 13px;
  }

  .ant-picker-input > input {
    margin-left: 51px;
    font-size: 12px !important;
    margin-top: 1px;
  }
  .ant-picker-input {
    top: 1px;
  }
  .date-picker {
    left: 145px;
  }
  .datepicker__to_title {
    margin-left: 200px;
    font-size: 13px;
    left: 0px;
  }
  .pagination {
    display: block;
    padding-right: 0px;
  }
}

@media (max-width: 414px) {
  .datepicker__to_title {
    margin-left: 194px;
  }
  .date-picker {
    left: 142px;
  }
}
@media (max-width: 395px) {
  .datepicker__to_title {
    margin-left: 200px;
  }
  .date-picker {
    left: 148px;
  }
}
@media (max-width: 390px) {
  .datepicker__to_title {
    margin-left: 196px;
  }
}
@media (max-width: 375px) {
  .datepicker__to_title {
    margin-left: 205px;
  }

  .ant-picker-input > input {
    margin-left: 55px;
    font-size: 12px !important;
    margin-top: 1px;
  }
  .ant-picker-input {
    top: 1px;
  }
  .date-picker {
    left: 148px;
  }
  .datepicker__to_title {
    margin-left: 202px;
    font-size: 13px;
  }
  .pagination {
    display: block;
    padding-right: 0px;
  }
}
@media (max-width: 360px) {
  /*  */

  .ant-picker-input > input {
    margin-left: 51px;
  }
  .ant-picker-input {
    top: 1px;
  }

  .datepicker__to_title {
    margin-left: 193px;
  }
  .pagination {
    display: block;
    padding-right: 0px;
  }
}

.MuiPagination-root .MuiPagination-ul li:first-child>button::before {
    margin: 7px !important;
    content: 'Previous' !important;
}

.MuiPagination-root .MuiPagination-ul li:first-child>button .MuiPaginationItem-icon {
    display: none !important;
}

.MuiPagination-root .MuiPagination-ul li:last-child>button .MuiPaginationItem-icon {
    display: none !important;
}

.MuiPagination-root .MuiPagination-ul li:last-child>button::before {
    margin: 7px !important;
    content: 'Next' !important;
}

.MuiPagination-root .MuiPagination-ul li>button.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #ffffff !important;
    border-color: #fdc727 !important;
}

.MuiPagination-root .MuiPagination-ul li>button.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root {
    background-color: #e0e0e0 !important
}

.makeStyles-root-292{
    padding-left: 5px;
}

textarea{
    font-Family: "Montserrat !important",
}
@media (max-width: 600px){
        .MuiPagination-root .MuiPagination-ul li>button.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root {
          background-color: #e0e0e0 !important;
          min-width:8px !important;
          height:19px !important;
         
        }
        .MuiPagination-root .MuiPagination-ul li:first-child>button::before {
            margin: 5px !important;
            content: 'Prev' !important;
        }}




.modalWrapSale {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Montserrat";
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 24;
  border-radius: 10px;
  height: 500px;
  overflow-x:  scroll ;
  scrollbar-width: 2px ;
}
.modalWrapSale::-webkit-scrollbar {
  width: 8px !important;

}

.modalWrapSale::-webkit-scrollbar-thumb {
  width: 8px !important;
  background-color: rgb(161, 161, 161);

}
@media (max-width: 1000px) {
  .modalWrapSale {
    width: 700px;
  }
}
@media (max-width: 600px) {
  .modalWrapSale {
    width: 90vw;
  }
}

.modalWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Montserrat";
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 24;
  border-radius: 10px;
  height: 500px;
  overflow-x:  scroll ;
  scrollbar-width: 2px ;
}
.modalWrap::-webkit-scrollbar {
  width: 5px !important;

}

.modalWrap::-webkit-scrollbar-thumb {
  width: 5px !important;
  background-color: rgb(161, 161, 161);

}


@media (max-width: 1000px) {
  .modalWrap {
    width: 700px;
  }
}
@media (max-width: 600px) {
  .modalWrap {
    width: 90vw;
  }
}

.salesAddress {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Montserrat";
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 24;
  border-radius: 10px;
  overflow: hidden scroll;
  scrollbar-width: none;
}


.salesAddress::-webkit-scrollbar {
  width: 8px !important;

}
.salesAddress::-webkit-scrollbar-thumb {
  width: 6px !important;
  background-color: rgb(161, 161, 161);
}
@media (max-width: 1000px) {
  .salesAddress {
    width: 700px;
  }
}
@media (max-width: 600px) {
  .salesAddress {
    width: 90vw;
  }
}
.toastify-toaster {
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  width: 80% !important;
}

.pac-container {
  z-index: 999999 !important;
}

.modalWrapCred{
   position: absolute;
   top: 50%;
   left: 50%;
   font-family: "Montserrat";
   -webkit-transform: translate(-50%,-50%);
           transform: translate(-50%,-50%);
   background-color: #ffffff;
   box-shadow: 24;
   border-radius: 10px;
   height: auto;
   overflow: hidden scroll;
   scrollbar-width: none;
}
.modalWrapCred::-webkit-scrollbar{
width: 6px !important;
}
.modalWrapCred::-webkit-scrollbar-thumb{
  width: 6px !important;
  background-color: rgb(155, 155, 155);
  }
@media (max-width: 1000px) {
  .modalWrapCred {
   width: 700px;
   height: 500px;
  }
}
@media (max-width: 600px) {
  .modalWrapCred {
   width: 90vw;
   height: 500px;
  }
}



.password-input .MuiTouchRipple-root{
  overflow: visible;
}
.modalWrap {
  position: absolute;
  top: "50%";
  left: "50%";
  height: "80%";
  overflow: auto;
  font-family: "Montserrat";
  -webkit-transform: "translate(-50%, -50%)";
          transform: "translate(-50%, -50%)";
  background-color: "#fff";
  box-shadow: 24;
  border-radius: "12px";
  height: 500px;
  overflow: hidden scroll;
  scrollbar-width: none;
}
.modalWrap::-webkit-scrollbar {
  width: 0 !important;
}
@media (max-width: 1000px) {
  .modalWrap {
    width: 90%;
  }
}

.modalWrap {
  position: absolute;
  top: "50%";
  left: "50%";
  width: 1100px;
  height: 650px;
  overflow: auto;
  font-family: "Montserrat";
  -webkit-transform: "translate(-50%, -50%)";
          transform: "translate(-50%, -50%)";
  background-color: "#fff";
  box-shadow: 24;
  border-radius: "12px";
  overflow: hidden scroll;
  scrollbar-width: none;
  
}

.modalWrapComment {
  position: absolute;
  top: "50%";
  left: "50%";
  width: 1100px;
  height: 650px;
  overflow: auto;
  font-family: "Montserrat";
  -webkit-transform: "translate(-50%, -50%)";
          transform: "translate(-50%, -50%)";
  background-color: "#fff";
  box-shadow: 24;
  border-radius: "12px";
  overflow: hidden scroll;
  scrollbar-width: none;
}

.comment-box{
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: center;
  justify-content: space-between;
  height: 300px;
}

.comment-close-button{
  background-color: #FEDD17;
  width: 120px;
  height: 35px;
  font-weight: bold;
  font-size: large;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.modalWrap::-webkit-scrollbar {
  width: 0 !important;
}

.modalWrapComment::-webkit-scrollbar {
  width: 0 !important;
}
@media (max-width: 1000px) {
  .modalWrap {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .modalWrapComment {
    width: 90%;
  }
}

.r-head-container{
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}
.loginMain {
  background-image: linear-gradient(#a3c2c2, #d1d1e0);
  background-repeat: initial;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* ['@media (max-width: 600px)']: {
      width: 200px;
    }, */
}

.paperStyle {
  padding: 20;
  height: "55vh";
  width: 450;
  margin: "20px auto";
  border-radius: "6px";
  color: "#990000";
  background-color: "transparent";
}
.forgotpaperStyle {
  padding: 20;
  height: "68vh";
  width: 510;
  margin: "20px auto";
  border-radius: "6px";
  color: "#990000";
  background-color: "transparent";
}
.grid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tf {
  margin-left: 60px;
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}
.type {
  margin-left: 210px;
}
.validationError {
  color: red;
  font-size: calc(3px + 1vmin);
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
}

::-webkit-file-upload-button {
    display: none;
}

#file-upload {
    /* display: block; */
    position: relative;
    left: 40%;
}

.image-section-inventory{
    width: 100%;
  padding: 1rem;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.image-section-inventory img{
   width: 10rem;
   border-radius: 10px;
  transition: all .2s ease;
  object-fit: cover;
}
.image-section-inventory img:hover{
    box-shadow: 0px 9px 25px -12px rgba(115,115,115,0.66);
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    
   
 }
.imageAdd{
    width: 10rem;
    background-color: rgb(235, 235, 235);
  
    height: 10rem;
    border-radius: 20px;
    transition: all .2s ease;
    cursor: pointer;
}
.imageAdd label{
   cursor: pointer;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}
.imageAdd:hover{
    
    background-color: rgb(216, 216, 216);
   
}
.admin-layout {
  display: flex;
  /* height: 130vh; */
  border-radius: 1rem;
  /* width: 70vw; */
  background-color: #E5E4E2;
  position: relative;
  overflow-x: hidden;
}

.makeStyles-divStyle-30 {
  width: 85vw;
  height: 100%;
  background-color: #F5F5F5;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  /* background-image: linear-gradient(to right, #F53803, #F5D020 ); */
  background-color: #FFFFFF;
  height: 100vh;
}

.headbar {
  display: "flex";
  flex-direction: "column";
  width: "500%";
  background-color: #FFFFFF;
}

/* .............................................................................. */

.adminmain{
  height: 98vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.adminSubMain{
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-template-columns: 18rem auto;
}

.container{
  width:"180vw";
}
.main {
  /* position: absolute; */
  /* background-color: #CE2929
;
    width: 100vw;
    height: 100vh; */
  display: flex;
}
.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  /* width: 20%; */
  height: 100vh;
  background-color: white;
  z-index: 1000;
}
.headbar {
  /* position : relative; */
  /* width: 80%; */
  /* height: 60px; */
  background-color: yellow;
}

