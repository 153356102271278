.date-picker {
  position: absolute;
  top: 11px;
  z-index: 10 !important;
  font-family: "Montserrat";
  left: 143px;
}

.ant-picker-input > input {
  border-color: #40a9ff;
  border-right-width: 1px;
  width: 80px;
  margin-left: 50px;
  font-family: Montserrat !important;
  font-size: 12px !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
  font-family: "Montserrat";
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
  font-family: "Montserrat";
}

.ant-picker-panels > *:last-child {
  display: none !important;
  font-family: "Montserrat";
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
  font-family: "Montserrat";
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
  font-family: "Montserrat";
}

.DayPicker-wrapper {
  outline: none;
  font-family: "Montserrat";
}

.datepicker__title {
  position: absolute;
  top: 11px;
  margin-left: 15px;
  font-weight: 400;
  z-index: 500;
  color: #8392ab;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
}

.datepicker__to_title {
  position: absolute;
  top: 11px;
  left: 210px;
  font-weight: 400;
  z-index: 500;
  color: #8392ab;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
}

.ant-picker:hover {
  border-color: #fedd17 !important;
  border-width: 1px;
  font-family: "Montserrat";
}

.ant-picker.ant-picker-focused {
  border-color: #fedd17 !important;
  border-width: 2px;
  font-family: "Montserrat";
}

.ant-picker {
  box-shadow: none !important;
  font-family: "Montserrat";
}

.ant-picker-input > input:placeholder {
  color: red !important;
  font-family: "Montserrat";
}

.anticon svg {
  display: none !important;
  font-family: "Montserrat";
}
.pagination {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 85px;
}
.paginationStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paginationPage {
  margin-left: 7px;
  font-family: "Montserrat";
}

@media (max-width: 428px) {
  .paginationStyle {
    margin-bottom: 10px;
  }
  .datepicker__title {
    margin-left: 14px;
    font-size: 13px;
  }

  .ant-picker-input > input {
    margin-left: 51px;
    font-size: 12px !important;
    margin-top: 1px;
  }
  .ant-picker-input {
    top: 1px;
  }
  .date-picker {
    left: 145px;
  }
  .datepicker__to_title {
    margin-left: 200px;
    font-size: 13px;
    left: 0px;
  }
  .pagination {
    display: block;
    padding-right: 0px;
  }
}

@media (max-width: 414px) {
  .datepicker__to_title {
    margin-left: 194px;
  }
  .date-picker {
    left: 142px;
  }
}
@media (max-width: 395px) {
  .datepicker__to_title {
    margin-left: 200px;
  }
  .date-picker {
    left: 148px;
  }
}
@media (max-width: 390px) {
  .datepicker__to_title {
    margin-left: 196px;
  }
}
@media (max-width: 375px) {
  .datepicker__to_title {
    margin-left: 205px;
  }

  .ant-picker-input > input {
    margin-left: 55px;
    font-size: 12px !important;
    margin-top: 1px;
  }
  .ant-picker-input {
    top: 1px;
  }
  .date-picker {
    left: 148px;
  }
  .datepicker__to_title {
    margin-left: 202px;
    font-size: 13px;
  }
  .pagination {
    display: block;
    padding-right: 0px;
  }
}
@media (max-width: 360px) {
  /*  */

  .ant-picker-input > input {
    margin-left: 51px;
  }
  .ant-picker-input {
    top: 1px;
  }

  .datepicker__to_title {
    margin-left: 193px;
  }
  .pagination {
    display: block;
    padding-right: 0px;
  }
}
