* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: " Poppins";
  font-style: "normal"; */
  /* z-index: 99; */
}

.main-container {
  display: flex;
}

main {
  padding: 10px;
}
.makeStyles-modalStyle-149::-webkit-scrollbar {
  width: 0 !important;
}

.makeStyles-table-133 {
  width: 100% !important;
}
.makeStyles-modalStyle-279::-webkit-scrollbar {
  width: 0 !important;
}
.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user-icons {
  font-size: 20px;
  cursor: pointer;
  color: #787885;
  /* text-align: 'center' */
}

.select {
  font-size: 19px;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: 22.5px;
  color: #8392ab;
  border: 0;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiTableCell-root {
  padding: 16px 3px !important;
}

.MuiStepper-root {
  margin-left: 0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  color: #adadad;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

/* .ant-picker:hover, .ant-picker-focused {
  border-color: yellow !important;
  border-right-width: 1px;
}

.anticon svg {
  display: none !important;
} */

::-webkit-input-placeholder {
  font-family: "Montserrat";
}
/* .ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px !important;
  margin-right: 10px !important;
  order: -1 !important;
} */

/* .input[type="text"],  input[type="number"], textarea  {
  -webkit-appearance: none;
  color: red;
} */

/* .MuiOutlinedInput-inputAdornedEnd {
  padding-right: 0 !important;
  color: #444445 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-family: 'Montserrat' !important;
  font-style: normal !important;

} */
/* .datepicker__title {
  position: absolute;
  top: 11px;
  margin-left: 15px;
  font-weight: 400;
  z-index: 1000;
  color: #8392AB;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
}

.datepicker__to_title {
  position: absolute;
  top: 11px;
  margin-left: 190px;
  font-weight: 400;
  z-index: 1000;
  color: #8392AB;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
} */

/* .ant-picker-input > input {
  border-color: #40a9ff;
  border-right-width: 1px;
  width: 80px;
  margin-left: 50px;
} */

/* .ant-picker-input > input {
  margin-left: 0 !important;
} */
