.main {
  /* position: absolute; */
  /* background-color: #CE2929
;
    width: 100vw;
    height: 100vh; */
  display: flex;
}
.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  /* width: 20%; */
  height: 100vh;
  background-color: white;
  z-index: 1000;
}
.headbar {
  /* position : relative; */
  /* width: 80%; */
  /* height: 60px; */
  background-color: yellow;
}
