.sidebar {
  width: "175vh";
}

.sidebarContent {
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.07em;
  font-weight: 400;
  font-size: 16px;
  height: 60px;
}

.pro-sidebar {
  width: 20vw;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
}

.scrollbarStyle {
  overflow-y: scroll;
  height: 100vh;
  /* webkit scrollbar */
}

/* .scrollbarStyle::-webkit-scrollbar {
} */

.scrollbarStyle::-webkit-scrollbar {
  width: 12px;
}

.scrollbarStyle::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 100px;
}

.scrollbarStyle::-webkit-scrollbar-thumb {
  background-color: #b4b2b0;
  border-radius: 100px;
  margin-inline: 5px;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: 100vh;
}

.pro-sidebar .pro-menu .pro-menu-item {
  color: #444445;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  .pro-inner-item
  .pro-icon-wrapper
  .pro-icon
  .active {
  color: #e94141;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #444445;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
}

.pro-sidebar .pro-menu a:hover,
.pro-sidebar .pro-menu .pro-menu-item .pro-inner-item:hover {
  color: #444445 !important;
  font-weight: 600 !important;
  font-size: "16px" !important;
  line-height: "19.5px" !important;
}

.pro-sidebar .pro-menu {
  color: red;
}

.pro-sidebar .pro-menu ul .pro-sub-menu .pro-inner-list-item {
  position: relative;
  /* background-color: #2b2b2b; */
}

.sidebar-header {
  height: 15vh;
}

.icon {
  flex-direction: "row";
  align-items: "flex-end";
  margin-left: 250px !important;
  color: red;
}

.subname {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.1em;
  font-style: normal;
  line-height: 50px;
  margin-top: 30px;
  margin-left: -11.5rem;
}

@keyframes animate {
  0%,
  18%,
  20%,
  50.1%,
  60%,
  65.1%,
  80.1%,
  90%,
  92% {
    color: black;
    text-shadow: none;
  }

  0%,
  18.1%,
  20%,
  50%,
  60.1%,
  65%,
  80.1%,
  90.1%,
  92%,
  100% {
    color: #0008;
    box-shadow: 0 0 10px #000000 0 0 20px #000000 0 0 40px #000000 0 0 80px
      #000000 0 0 160px #000000;
  }
}

.postname {
  color: #ffffff;
  font-weight: 900;
  font-size: 40px;
  letter-spacing: 0.15em;
  line-height: 50px;
  margin-bottom: 10px;
  cursor: pointer;
}

.name {
  display: flex;
  position: absolute;
  left: 1.9rem;
  top: 0.5rem;
  padding-left: 5px;
}

.smallname {
  display: flex;
  position: absolute;
  left: 1rem;
  top: 0.5rem;
}

.sidebar-footer {
  position: absolute;
  bottom: 0px;
}

.active {
  color: #444445 !important;
  font-weight: 600 !important;
  font-size: "16px" !important;
  line-height: "19.5px" !important;
}

.active .active-icon {
  color: yellow !important;
}

.link {
  color: rgba(68, 68, 69, 0.7);
}

.react-slidedown,
.pro-inner-list-item {
  background-color: #ffffff !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  flex-grow: 1;
  flex-shrink: 1;
  color: #444445;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow {
  border-color: #292d32;
}
@media (max-width: 600px) {
  .pro-sidebar {
    position: sticky;
    top: 0;
    right: 0;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
    margin-top: 50px;
  }
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  background-color: #fff !important;
  z-index: 999999 !important;
}

.pro-sidebar.collapsed {
  z-index: 9999999;
}

.subinmenu .pro-inner-list-item{
  padding-left: 8px !important;
}
.subinmenu .pro-inner-item{
  padding-left: 0px !important;
  margin-left: 20px;
}
