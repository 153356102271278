.ok{
   
    width: 100%;
    font-size: 16px;
    background-color: #FEDD17;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    outline: none;
    border: none;
 
   
}
