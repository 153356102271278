.MuiPagination-root .MuiPagination-ul li:first-child>button::before {
    margin: 7px !important;
    content: 'Previous' !important;
}

.MuiPagination-root .MuiPagination-ul li:first-child>button .MuiPaginationItem-icon {
    display: none !important;
}

.MuiPagination-root .MuiPagination-ul li:last-child>button .MuiPaginationItem-icon {
    display: none !important;
}

.MuiPagination-root .MuiPagination-ul li:last-child>button::before {
    margin: 7px !important;
    content: 'Next' !important;
}

.MuiPagination-root .MuiPagination-ul li>button.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #ffffff !important;
    border-color: #fdc727 !important;
}

.MuiPagination-root .MuiPagination-ul li>button.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root {
    background-color: #e0e0e0 !important
}

.makeStyles-root-292{
    padding-left: 5px;
}

textarea{
    font-Family: "Montserrat !important",
}
@media (max-width: 600px){
        .MuiPagination-root .MuiPagination-ul li>button.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root {
          background-color: #e0e0e0 !important;
          min-width:8px !important;
          height:19px !important;
         
        }
        .MuiPagination-root .MuiPagination-ul li:first-child>button::before {
            margin: 5px !important;
            content: 'Prev' !important;
        }}



