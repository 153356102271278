.modalWrap {
  position: absolute;
  top: "50%";
  left: "50%";
  width: 1100px;
  height: 650px;
  overflow: auto;
  font-family: "Montserrat";
  transform: "translate(-50%, -50%)";
  background-color: "#fff";
  box-shadow: 24;
  border-radius: "12px";
  overflow: hidden scroll;
  scrollbar-width: none;
  
}

.modalWrapComment {
  position: absolute;
  top: "50%";
  left: "50%";
  width: 1100px;
  height: 650px;
  overflow: auto;
  font-family: "Montserrat";
  transform: "translate(-50%, -50%)";
  background-color: "#fff";
  box-shadow: 24;
  border-radius: "12px";
  overflow: hidden scroll;
  scrollbar-width: none;
}

.comment-box{
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: center;
  justify-content: space-between;
  height: 300px;
}

.comment-close-button{
  background-color: #FEDD17;
  width: 120px;
  height: 35px;
  font-weight: bold;
  font-size: large;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.modalWrap::-webkit-scrollbar {
  width: 0 !important;
}

.modalWrapComment::-webkit-scrollbar {
  width: 0 !important;
}
@media (max-width: 1000px) {
  .modalWrap {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .modalWrapComment {
    width: 90%;
  }
}
