.modalWrapCred{
   position: absolute;
   top: 50%;
   left: 50%;
   font-family: "Montserrat";
   transform: translate(-50%,-50%);
   background-color: #ffffff;
   box-shadow: 24;
   border-radius: 10px;
   height: auto;
   overflow: hidden scroll;
   scrollbar-width: none;
}
.modalWrapCred::-webkit-scrollbar{
width: 6px !important;
}
.modalWrapCred::-webkit-scrollbar-thumb{
  width: 6px !important;
  background-color: rgb(155, 155, 155);
  }
@media (max-width: 1000px) {
  .modalWrapCred {
   width: 700px;
   height: 500px;
  }
}
@media (max-width: 600px) {
  .modalWrapCred {
   width: 90vw;
   height: 500px;
  }
}



.password-input .MuiTouchRipple-root{
  overflow: visible;
}