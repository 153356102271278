.loginMain {
  background-image: linear-gradient(#a3c2c2, #d1d1e0);
  background-repeat: initial;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* ['@media (max-width: 600px)']: {
      width: 200px;
    }, */
}

.paperStyle {
  padding: 20;
  height: "55vh";
  width: 450;
  margin: "20px auto";
  border-radius: "6px";
  color: "#990000";
  background-color: "transparent";
}
.forgotpaperStyle {
  padding: 20;
  height: "68vh";
  width: 510;
  margin: "20px auto";
  border-radius: "6px";
  color: "#990000";
  background-color: "transparent";
}
.grid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tf {
  margin-left: 60px;
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}
.type {
  margin-left: 210px;
}
.validationError {
  color: red;
  font-size: calc(3px + 1vmin);
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
}
