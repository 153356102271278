.modalWrap {
  position: absolute;
  top: "50%";
  left: "50%";
  height: "80%";
  overflow: auto;
  font-family: "Montserrat";
  transform: "translate(-50%, -50%)";
  background-color: "#fff";
  box-shadow: 24;
  border-radius: "12px";
  height: 500px;
  overflow: hidden scroll;
  scrollbar-width: none;
}
.modalWrap::-webkit-scrollbar {
  width: 0 !important;
}
@media (max-width: 1000px) {
  .modalWrap {
    width: 90%;
  }
}
